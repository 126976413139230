<template>
  <!--
    画面: 宿リスト一覧テーブル
    用途: 登録された宿リストのデータを一覧表示する
   -->
  <div>
    <!-- #region Table -->
    <CDataTable
      border
      hover
      size="sm"
      :fields="tableFields"
      :items="tableItems"
      v-if="canShowTable"
    >
      <td slot="name" slot-scope="{ item }">
        <span class="ml-2 text-left">{{ item.name }}</span>
      </td>
      <td slot="status" slot-scope="{ item }" class="text-center p-0">
        <CSwitch
          class="mt-1 swc sw-2"
          color="info"
          variant="opposite"
          :labelOn="$t('common.active')"
          :labelOff="$t('common.inActive')"
          :checked="item.status | masterStatus"
          @update:checked="onStatusChanged(item)"
        />
      </td>
      <template #action="{ item }">
        <td class="p-0 text-center">
          <CButton
            class="mt-1"
            color="info"
            square
            size="sm"
            @click="onEditClicked(item.id)"
            ><i class="icon cil-pencil mr-1"></i
            >{{ $t("common.edit") }}</CButton
          >
        </td>
      </template>
    </CDataTable>
    <!-- #endregion Table -->

    <!-- #region Alert -->
    <CAlert color="danger" class="mt-3 text-center" v-if="notFound">
      宿リストはありません。
    </CAlert>
    <!-- #endregion Alert -->

    <ErrorModal
      :errorModal="errorModal"
      :errorMessage="errorMessage"
      @close="errorModal = $event"
    />
  </div>
</template>

<script>
import i18n from "@/i18n";
import STATUS from "@/mixins/property";
export default {
  name: "FacilityListTable",
  mixins: [STATUS],
  data() {
    return {
      //#region Flag
      errorModal: false,
      //#endregion Flag

      //#region Table

      /** 一覧表示データ */
      tableItems: [],

      /** 一覧列名 */
      tableFields: [
        {
          key: "name",
          label: `${i18n.t("facilityListListPage.name")}`,
          _classes: "text-center bg-light",
        },
        {
          key: "status",
          label: `${i18n.t("facilityListListPage.status")}`,
          _classes: "text-center bg-light",
          _style: "width: 12%",
        },
        {
          key: "action",
          label: `${i18n.t("facilityListListPage.action")}`,
          _classes: "text-center bg-light",
          _style: "width: 12%",
        },
      ],

      //#region Response data

      response: [],
      errorMessage: "",

      //#endregion Response data
    };
  },

  computed: {
    /** 一覧表表示できるか？ */
    canShowTable() {
      // return !this.loading && !this.notFound;
      return true; // TODO: 開発用
    },

    /** レスポンスデータが見つからないか？ */
    notFound() {
      return this.response.length < 1;
    },
  },

  methods: {
    //#region Event

    /** 有効無効切替 */
    onStatusChanged(item) {
      // TODO: 有効無効ステータス更新リクエスト
      item.status = this.toggleStatus(item.status);
      this.reqPutStatus(item);
    },

    /** 編集ボタン押下 */
    onEditClicked(id) {
      const facilityListId = id;
      this.$router.push({
        path: `/admin/facilityList/${facilityListId}/edit`,
      });
    },

    //#endregion Event

    //#region Request

    /** 有効無効ステータス更新 */
    reqPutStatus(item) {
      const listId = item.id;
      const body = { status: item.status };

      const callback = () => {
        this.reqGet();
      };

      const errorCallback = (err) => {
        this.errorMessage = `${err.name} : ${err.message}`;
        this.errorModal = true;
        console.log(err);
        //testing reload page?
        this.reqGet();
      };

      this.reqPutHotelListStatus(listId,body,callback,errorCallback);
    },

    
    /** 宿リスト一覧取得 */
    reqGet() {

      const callback = (a) => {
         const datas = a.lists;
          this.response = datas;
          // 取得データを一覧表に流し込み
          this.pourTable(datas);
          this.loading = false;
      };

      const errorCallback = (err) => {
        this.errorMessage = `${err.name} : ${err.message}`;
        this.loading = false;
      };

      this.reqGetHotelListAll(callback,errorCallback);
    },

    //#endregion Request

    /**
     * 一覧表にデータを流し込む
     * @param datas
     */
    pourTable(datas) {
      this.clearTable();
      datas.forEach((data) => {
        this.tableItems.push({
          id: data.id,
          name: data.name,
          status: data.status,
        });
      });
    },

    /**
     * 一覧表をクリア
     */
    clearTable() {
      this.tableItems = [];
    },

    /**  */
    createTableItems(tableItem) {
      const tableItems = new Array(tableItem);
      return tableItems;
    },
    toggleStatus(status) {
      return status == this.STATUS.VALID
        ? this.STATUS.INVALID
        : this.STATUS.VALID;
    },
    //#endregion Method
  },

  beforeMount() {
    this.reqGet();
  },
};
</script>
